import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import QueryLink from "../components/QueryLink";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import Pagination from "../components/Pagination";

import OnNextSubmit from "../components/OnNextSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import { useOutletContext } from "react-router-dom";
import { klachtCompProps } from "../types/redux";
import { extra_klacht_data } from "../types/klacht_data";
import { praktijkinfo } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { extra_notities } from "components/extra_notities";
import { MedicatieOverzicht } from "components/MedicatieOverzichtVraag";
let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface HogeVullingProps extends klachtCompProps {}

const HogeVulling: React.FC<HogeVullingProps> = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const praktijkInfo: praktijkinfo = useOutletContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });

  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("middenonder"),
    t("middenboven"),
    t("op onbekende plek"),
  ];

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, "Hoge vulling");
  const extra_data: extra_klacht_data = { klacht: "Hoge vulling" };
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );

  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            {/* <FormControl fullWidth variant="standard" error={!!errors.tand_locatie}>
                            <InputLabel htmlFor="age-native-simple">{t("Wat is de locatie van de tand")}?</InputLabel>
                            <Select
                                // displayEmpty
                                inputProps={register('tand_locatie', { required: "This is required message" })}
                                native

                            >
                                <option value={"Rechtsboven"}>{t("Rechtsboven")}</option>
                                <option value={"Middenboven"}>{t("Middenboven")}</option>
                                <option value={"Linksboven"}>{t("Linksboven")}</option>
                                <option value={"Linksonder"}>{t("Linksonder")}</option>
                                <option value={"Middenonder"}>{t("Middenonder")}</option>
                                <option value={"Rechtsonder"}>{t("Rechtsonder")}</option>
                                <option value={"Op onbekende plek"}>{t("Onbekend")}</option>

                            </Select>
                        </FormControl> */}

            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de tand")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "middenonder",
                  "middenboven",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>

          {is_spoed_praktijk && extra_notities(t, register, errors, control)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default PijnTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(HogeVulling);
