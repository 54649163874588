import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import QueryLink from "../components/QueryLink";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import Pagination from "../components/Pagination";
import { useOutletContext } from "react-router-dom";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import OnNextSubmit from "../components/OnNextSubmit";
import { Outlet } from "react-router-dom";
import { praktijkinfo } from "../types/praktijkinfo";
import { uploadPhoto_utils } from "../utils/uploadPhoto_utils";
import { extra_klacht_data, klacht_data } from "../types/klacht_data";
import { radiogroep_util } from "../utils/radiogroep_util";
import { Dispatch, Action, AnyAction } from "redux";
import { photoBox } from "utils/photoBox";
import { extra_notities } from "../components/extra_notities";
import { MedicatieOverzicht } from "components/MedicatieOverzichtVraag";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface PijnTandProps {
  formData: klacht_data;
  dispatch?: Dispatch;
}

const PijnTand: React.FC<PijnTandProps> = (props) => {
  const praktijkInfo: praktijkinfo = useOutletContext();

  const [foto, setFoto] = React.useState(props.formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });
  const { t, i18n } = useTranslation();

  const marks = [
    {
      value: 1.5,
      label: t("Weinig pijn"),
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 4,
      label: "",
    },
    {
      value: 5,
      label: "",
    },
    {
      value: 6,
      label: "",
    },
    {
      value: 7,
      label: "",
    },
    {
      value: 8,
      label: "",
    },
    {
      value: 9,
      label: "",
    },

    {
      value: 9.5,
      label: t("Veel pijn"),
    },
  ];

  const prikkels = [
    t("kou"),
    t("warmte"),
    t("druk"),
    t("zoet"),
    t("zuur"),
    t("anders"),
  ];
  const pijnstillers = [t("ibuprofen"), t("paracetamol"), t("anders")];
  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("middenonder"),
    t("middenboven"),
    t("op onbekende plek"),
  ];

  const uploadPhoto = uploadPhoto_utils(setFoto);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, foto, "Pijn aan tand");
  const extra_data: extra_klacht_data = { klacht: "Pijn aan tand" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );

  const radiogroep = radiogroep_util(errors, control);

  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />

      <Typography>{t("Vul de volgende gegevens in")}:</Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de tand")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "middenonder",
                  "middenboven",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              component="fieldset"
              error={!!errors.tijd_geleden}
            >
              <FormLabel sx={{ mb: 2 }} component="legend">
                {t("Sinds wanneer heeft u last van een pijnlijke tand?")}
              </FormLabel>

              <Grid container spacing={1}>
                <Grid item xs={4} md={3}>
                  <TextField
                    variant="standard"
                    type="number"
                    InputProps={register("tijd_geleden", { required: true })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ minWidth: 120 }}
                    error={!!errors.tijd_eenheid}
                  >
                    <Select
                      inputProps={register("tijd_eenheid", { required: true })}
                      native
                    >
                      <option value={"dagen"}>{t("dagen")}</option>
                      <option value={"weken"}>{t("weken")}</option>
                      <option value={"maanden"}>{t("maanden")}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              [
                "dof",
                "stekend",
                "scherp",
                "zeurend",
                "kloppend",
                "moeilijk te omschrijven",
              ],
              [
                t("dof"),
                t("stekend"),
                t("scherp"),
                t("zeurend"),
                t("kloppend"),
                t("moeilijk te omschrijven"),
              ],
              "pijn_omschrijving",
              t("Hoe omschrijft u de pijn? ")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["spontaan", "werd_langzaam_erger"],
              [t("spontaan"), t("werd_langzaam_erger")],
              "pijn_ontstaan",
              t("Hoe is de pijn ontstaan?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "prikkels",
              t("Zijn er prikkels die de pijn erger maken?")
            )}
          </Grid>

          {watch("prikkels") == "ja" && (
            <Grid mt={-1} item xs={12}>
              <FormGroup>
                <FormLabel>{t("Welke prikkels?")}</FormLabel>

                <div>
                  {["kou", "warmte", "druk", "zoet", "zuur", "anders"].map(
                    (value, idx) => {
                      return (
                        <FormControlLabel
                          key={value}
                          control={
                            <Controller
                              // @ts-ignore
                              name={value + "_prikkel"}
                              control={control}
                              rules={{ required: false }}
                              render={({ field }) => <Checkbox {...field} />}
                            />
                          }
                          label={
                            prikkels[idx].charAt(0).toUpperCase() +
                            prikkels[idx].slice(1)
                          }
                        />
                      );
                    }
                  )}
                </div>
              </FormGroup>
            </Grid>
          )}

          {watch("prikkels") == "ja" && watch("anders_prikkel") && (
            <Grid mt={-4} item xs={12}>
              <TextField
                fullWidth
                error={!!errors.andere_prikkel}
                variant="standard"
                label={t("Andere prikkel(s)")}
                InputProps={register("andere_prikkel", { required: false })}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "zwelling",
              t("Is er sprake van zwelling?")
            )}
          </Grid>
          {is_spoed_praktijk && watch("zwelling") === "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "beperkte_mondopening",
                t("Is er sprake van een beperkte mondopening?")
              )}
            </Grid>
          )}
          {is_spoed_praktijk && watch("zwelling") === "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "hoofdhals_zwelling",
                t("Is er sprake van een zwelling in het hoofdhals gebied?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "koorts",
              t("Heeft u koorts?")
            )}
          </Grid>
          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "pijnstillers",
              t("Heeft u pijnstillers genomen?")
            )}
          </Grid>

          {watch("pijnstillers") == "ja" && (
            <Grid mt={-1} item xs={12}>
              <FormGroup>
                <FormLabel>{t("Welke pijnstiller(s)?")}</FormLabel>

                <div>
                  {["ibuprofen", "paracetamol", "anders"].map((value, idx) => {
                    return (
                      <FormControlLabel
                        key={value}
                        control={
                          <Controller
                            // @ts-ignore
                            name={value + "_pijnstiller"}
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => <Checkbox {...field} />}
                          />
                        }
                        label={
                          pijnstillers[idx].charAt(0).toUpperCase() +
                          pijnstillers[idx].slice(1)
                        }
                      />
                    );
                  })}
                </div>
              </FormGroup>
            </Grid>
          )}
          {watch("pijnstillers") == "ja" && watch("anders_pijnstiller") && (
            <Grid mt={-4} item xs={12}>
              <TextField
                fullWidth
                error={!!errors.andere_pijnstiller}
                variant="standard"
                label={t("Andere pijnstiller(s)")}
                InputProps={register("andere_pijnstiller", { required: false })}
              />
            </Grid>
          )}

          {watch("pijnstillers") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "helpen_pijnstillers",
                t("Helpen de pijnstillers?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "bekend_klemmen_knarsen",
              t("Bent u bekend met klemmen of knarsen?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "stress",
              t("Heeft u de afgelopen periode veel stress gehad? ")
            )}
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormLabel>
                {t("Geef de pijn een cijfer van 1 tot 10")}?
              </FormLabel>
              <Controller
                control={control}
                name="pijncijfer"
                defaultValue={5}
                render={({ field }) => (
                  <Slider
                    {...field}
                    aria-label="Always visible"
                    step={1}
                    marks={marks}
                    min={1}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                )}
              />
            </FormGroup>
          </Grid>



          {is_spoed_praktijk && extra_notities(t, register, errors, control)}

          {watch("zwelling") == "ja" && photoBox(t, foto, uploadPhoto)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default PijnTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(PijnTand);
