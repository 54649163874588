import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import QueryLink from "../components/QueryLink";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import OnNextSubmit from "../components/OnNextSubmit";
import Pagination from "../components/Pagination";
import { useOutletContext } from "react-router-dom";
import { klachtCompProps } from "../types/redux";
import { extra_klacht_data } from "../types/klacht_data";
import { praktijkinfo } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { extra_notities } from "components/extra_notities";
import { MedicatieOverzicht } from "components/MedicatieOverzichtVraag";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface ImplantaantProps extends klachtCompProps {}

const Implantaat: React.FC<ImplantaantProps> = (props) => {
  const navigate = useNavigate();
  const [foto, setFoto] = React.useState(props.formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });

  const { t, i18n } = useTranslation();

  const praktijkInfo: praktijkinfo = useOutletContext();

  const problemen = [
    t("Rondom gevoelig"),
    t("onderdeel losgekomen"),
    t("afgebroken"),
    t("anders"),
  ];

  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("middenonder"),
    t("middenboven"),
    t("op onbekende plek"),
  ];

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, "Implantaat");
  const extra_data: extra_klacht_data = { klacht: "Implantaat" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );

  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de implantaat")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "middenonder",
                  "middenboven",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            {radiogroep(
              [
                "Rondom gevoelig",
                "afdekschroef_losgekomen",
                "kroon_losgekomen",
                "afgebroken",
                "is_er_volledig_uit",
                "anders",
              ],
              [
                t("Rondom gevoelig"),
                t("Afdekschroef losgekomen"),
                t("Kroon losgekomen"),
                t("afgebroken"),
                t("is_er_volledig_uit"),
                t("anders"),
              ],
              "implantaat_probleem",
              t("Wat is het probleem?")
            )}
          </Grid>

          {watch("implantaat_probleem") === "anders" && (
            <Grid mt={-4} item xs={12}>
              <TextField
                fullWidth
                error={!!errors.ander_probleem_implantaat}
                variant="standard"
                label={t("Ander probleem")}
                InputProps={register("ander_probleem_implantaat", {
                  required: true,
                })}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl
              fullWidth
              component="fieldset"
              error={!!errors.tijd_geleden}
            >
              <FormLabel sx={{ mb: 2 }} component="legend">
                {t("Sinds wanneer heeft u deze klacht?")}
              </FormLabel>

              <Grid container spacing={1}>
                <Grid item xs={4} md={3}>
                  <TextField
                    variant="standard"
                    type="number"
                    InputProps={register("tijd_geleden", { required: true })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ minWidth: 120 }}
                    error={!!errors.tijd_eenheid}
                  >
                    <Select
                      inputProps={register("tijd_eenheid", { required: true })}
                      native
                    >
                      <option value={"dagen"}>{t("dagen")}</option>
                      <option value={"weken"}>{t("weken")}</option>
                      <option value={"maanden"}>{t("maanden")}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          {is_spoed_praktijk && extra_notities(t, register, errors, control)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Implantaat);
