import { Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import QueryLink from "./QueryLink";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import React from "react";

interface BackSubmitProps {
  loading: boolean;
  back_link: string;
  submitBackButton: boolean;
}

export default function BackSubmit({
  loading,
  back_link,
  submitBackButton = true,
}: BackSubmitProps) {
  const { t, i18n } = useTranslation();
  return (
    <Box sx={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
      {submitBackButton && (
        <QueryLink to={back_link}>
          <Button
            variant="outlined"
            sx={{
              marginTop: 3,
              marginLeft: 3,
            }}
          >
            {t("Terug")}
          </Button>
        </QueryLink>
      )}

      <LoadingButton
        loading={loading}
        sx={{
          marginTop: 3,
          marginLeft: 1,
        }}
        onClick={() => console.log("clicked")}
        type="submit"
        loadingPosition="end"
        endIcon={<SendIcon />}
        variant="contained"
        color="primary"
      >
        {t("Versturen")}
      </LoadingButton>
    </Box>
  );
}
