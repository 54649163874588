import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import { ErrorMessage } from "@hookform/error-message";

import Pagination from "../components/Pagination";

import OnNextSubmit from "../components/OnNextSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import { useOutletContext } from "react-router-dom";
import { extra_klacht_data, klacht_data } from "../types/klacht_data";
import { praktijkinfo_load } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { uploadPhoto_utils } from "../utils/uploadPhoto_utils";
import { Dispatch, Action, AnyAction } from "redux";
import { photoBox } from "utils/photoBox";
import { extra_notities } from "components/extra_notities";
import { MedicatieOverzicht } from "components/MedicatieOverzichtVraag";

let loading = false;

const setLoading = (bool: boolean) => {
  loading = bool;
};

interface Props {
  formData: klacht_data;
  dispatch: Dispatch;
}

const Prothese: React.FC<Props> = ({ dispatch, formData }) => {
  const praktijkInfo: praktijkinfo_load = useOutletContext();

  const [foto, setFoto] = React.useState(formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: formData,
  });

  const { t, i18n } = useTranslation();

  const uploadPhoto = uploadPhoto_utils(setFoto);

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, foto, "Prothese");
  const extra_data: extra_klacht_data = { klacht: "Prothese" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }
  const onNextSubmit = OnNextSubmit(dispatch, extra_data, "/persoonsgegevens");
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            {radiogroep(
              ["boven", "onder"],
              [t("boven"), t("onder")],
              "welke_prothese",
              t("Om welke prothese gaat het?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "prothese_los",
              t("Zit de prothese los?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "drukplek_lange_rand",
              t("Is er sprake van een drukplekje of een te lange rand?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "scheurtje",
              t("Zit er een scheurtje of barstje in de prothese?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "prothese_gebroken",
              t("Is de prothese gebroken?")
            )}
          </Grid>

          {watch("scheurtje") == "nee" &&
            watch("drukplek_lange_rand") == "nee" &&
            watch("prothese_los") == "nee" &&
            watch("prothese_gebroken") == "nee" && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  component="fieldset"
                  error={!!errors.klacht_uitleg}
                >
                  <FormLabel sx={{ mb: 1 }} component="legend">
                    {t("Kunt u de klacht zo goed mogelijk omschrijven?")}
                  </FormLabel>

                  <TextField
                    placeholder={t("Licht toe") + "..."}
                    InputProps={register("klacht_uitleg", { required: true })}
                    multiline
                    // rows={2}
                    maxRows={4}
                  />
                </FormControl>
              </Grid>
            )}

          {is_spoed_praktijk && extra_notities(t, register, errors, control)}

          {photoBox(t, foto, uploadPhoto)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Prothese);
