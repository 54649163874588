import React from "react";
import { UseFormRegister } from "react-hook-form";
import { klacht_data } from "../types/klacht_data";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { radiogroep_util } from "../utils/radiogroep_util";

export function extra_notities(
  t: any,
  register: UseFormRegister<klacht_data>,
  errors: any,
  control: any = null
): React.ReactNode {
  const CustTextField = ({ name, label }: { name: string; label: string }) => (
    <Grid item xs={12}>
      <FormControl fullWidth component="fieldset" error={!!errors[name]}>
        <FormLabel sx={{ mb: 1 }} component="legend">
          {label}
        </FormLabel>

        <TextField
          placeholder={"Licht toe..."}
          InputProps={register(name, { required: false })}
          multiline
          minRows={1}
          maxRows={1}
        />
      </FormControl>
    </Grid>
  );

  const radiogroep = radiogroep_util(errors, control);

  return (
    <>
      <Grid item xs={12}>
        {radiogroep(
          ["geopend", "gesloten"],
          [t("geopend"), t("gesloten")],
          "eigen_tandarts_geopend",
          t("Is de eigen tandarts geopend of gesloten?")
        )}
      </Grid>
      <CustTextField
        name="waarom_niet_eigen_tandarts"
        label="Wat is de reden dat u niet bij uw eigen tandarts geholpen kunt worden?"
      />
      <Grid item xs={12} mt={1}>
        <FormControl component="fieldset" error={!!errors.verder_medicatie}>
          <FormLabel required={true} component="legend">
            {t(
              "Gebruikt u verder nog medicatie? Zo ja, neem een medicatieoverzicht mee naar uw afspraak!"
            )}
          </FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="verder_medicatie"
            render={({ field }) => {
              return (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="ja"
                    control={<Radio />}
                    label={t("Ja")}
                  />
                  <FormControlLabel
                    value="nee"
                    control={<Radio />}
                    label={t("Nee")}
                  />
                </RadioGroup>
              );
            }}
          />
        </FormControl>
      </Grid>{" "}
      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel sx={{ mb: 1 }} component="legend">
            {t("Extra notities (optioneel):")}
          </FormLabel>
          <TextField
            placeholder={t("Licht toe") + "..."}
            InputProps={register("extra_notitie", { required: false })}
            multiline
            minRows={2}
            maxRows={5}
          />
        </FormControl>
      </Grid>
    </>
  );
}
